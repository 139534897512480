import { AWARD_CODES } from "../../../common/enums/award-codes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { useGetBronzeProgramStatusQuery } from "../../../services/bronzeIncentivesAPI";
import { updateProgramStatusDetails } from "../../../reducers/bronzeIncentive";
import { storedLocale } from "../../impersonation/util";
import BronzeDisplayRules from "../BronzeCommon/BronzeDisplayRules/BronzeDisplayRules";
import BronzeIncentivesLoader from "../BronzeCommon/BronzeIncentivesLoader/BronzeIncentivesLoader";
import BBIIncentiveProgress from "./BBIIncentiveProgress/BBIIncentiveProgress";
import BBIMetric from "./BBIMetric/BBIMetric";
import BBIConsistencyPaymentMetric from "./BBIConsistencyPaymentMetric/BBIConsistencyPaymentMetric";
import BBIBannerTile from "./BBIBannerTile/BBIBannerTile";
import "./BronzeBuilder.scss";
import BBIEligibilityBannerTile from "./BBIEligibilityBannerTile/BBIEligibilityBannerTile";
import BBIRequirements from "./BBIRequirements/BBIRequirements";

const BronzeBuilder = () => {
  const dispatch = useAppDispatch();
  const locale = storedLocale();
  const boot = useAppSelector(({ boot }) => boot);
  const { isoCountryCode } = boot.user;
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });

  const primaryAwardCode = isoCountryCode === "MN" ? AWARD_CODES.MN_BRONZE_BUILDER : AWARD_CODES.BRONZE_BUILDER;

  const { data, isFetching } = useGetBronzeProgramStatusQuery({
    affAbo,
    period: selectedPeriod,
    primaryAwardCode,
    fixedPayment: AWARD_CODES.FIXED_PAYMENT,
    restart1: AWARD_CODES.RESTART_BRONZE_BUILDER,
    restart2: AWARD_CODES.RESTART_TWO_BRONZE_BUILDER,
  });

  if (isFetching === false && data) {
    const { awardCode, isFixedPayment, fixedPaymentAwardCode } = data;

    dispatch(updateProgramStatusDetails(data));

    return (
      <BronzeDisplayRules affAbo={affAbo} period={selectedPeriod} awardCode={awardCode} locale={locale}>
        {({ displayBannerOnly, displayEligibleBannerOnly, showAllBannerTiles }) => (
          <div
            className={`bronze-builder bronze-builder__${displayBannerOnly ? "banner-only" : ""} bronze-builder__${
              displayEligibleBannerOnly ? "eligible-banner-only" : ""
            }`}
          >
            <div className="bronze-builder">
              <div className="bronze-builder__overview">
                <div className="bronze-builder__overview__banner">
                  {/** todo: when only display banner, set compact view to false */}
                  <BBIBannerTile
                    awardCode={awardCode}
                    isFixedPayment={isFixedPayment}
                    isCompactView={true}
                    fixedPaymentAwardCode={fixedPaymentAwardCode}
                  />
                </div>
                <div className="bronze-builder__overview__tracking">
                  <BBIIncentiveProgress awardCode={awardCode} show={showAllBannerTiles} />
                </div>
                <div className="bronze-builder__overview__eligible">
                  <BBIEligibilityBannerTile awardCode={awardCode} show={displayBannerOnly === false} />
                </div>
              </div>
              <div className="bronze-builder__incentive-calculation">
                <BBIMetric awardCode={awardCode} show={showAllBannerTiles} />
                <BBIConsistencyPaymentMetric
                  awardCode={awardCode}
                  isFixedPayment={isFixedPayment}
                  fixedPaymentAwardCode={fixedPaymentAwardCode}
                  show={showAllBannerTiles}
                />
              </div>
              <div className="bronze-builder__requirements">
                <BBIRequirements awardCode={awardCode} show={showAllBannerTiles} />
              </div>
            </div>
          </div>
        )}
      </BronzeDisplayRules>
    );
  }
  return <BronzeIncentivesLoader />;
};

export default BronzeBuilder;
