import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useEffect, useState } from "react";
import { storedLocale } from "../../impersonation/util";
import TEALIUM from "../../../common/enums/tealium-enums";
import { store, useAppSelector } from "../../../store";
import { useGetPathToBronzeOverviewQuery } from "../../../services/bronzeIncentivesAPI";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { PathToBronzeOverviewResponse } from "../../../reducers/model/bronze/pathToBronze";
import { getStatusForTealium, returnDefaultData } from "./pathToBronzeUtils";

export const FetchPtbData = (period: string) => {
  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const { user } = boot;
  const {
    data: payloadData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetPathToBronzeOverviewQuery({
    affAbo: affAbo,
    period,
    locale: storedLocale(),
    countryCode: user.isoCountryCode,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<PathToBronzeOverviewResponse>(returnDefaultData());

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isQueryLoading || isFetching) {
      setIsLoading(true);
    }
  }, [isQueryLoading, isFetching, period]);

  useEffect(() => {
    if (isError) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  // once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (!isQueryLoading && !isFetching && !isError) {
      setData(payloadData || returnDefaultData());
      setIsLoading(false);
      setErrorStatus(false);
      track(
        TEALIUM.U_TAG_METHOD.VIEW,
        TEALIUM.EVENT_NAME.CLICK_ACTION,
        TEALIUM.CATEGORY.PATH_TO_BRONZE,
        TEALIUM.CATEGORY.PATH_TO_BRONZE,
        `${getStatusForTealium(payloadData || returnDefaultData())}:${period}`,
        "",
      );
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  return { isLoading, data, errorStatus };
};
