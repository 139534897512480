import { BronzeRequirementsParam, BronzeRequirementsResponse } from "../reducers/model/bronze/requirements";
import { BronzeEligibilityParam, BronzeEligibilityResponse } from "../reducers/model/bronze/eligibility";
import { BronzeEarningParam, BronzeEarningResponse } from "../reducers/model/bronze/earning";
import { ProgramStatusParam, ProgramStatusResponse } from "../reducers/model/bronze/programStatus";
import { PathToBronzeOverviewParam, PathToBronzeOverviewResponse } from "../reducers/model/bronze/pathToBronze";
import { accAPI } from ".";

export const bronzeAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getBronzeRequirements: build.query<BronzeRequirementsResponse, BronzeRequirementsParam>({
      query: (params) => {
        const { affAbo, period, awardCode, locale, countryCode, isStandarRetailEnhancementCountry } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
          countryCode,
          isStandarRetailEnhancementCountry: `${isStandarRetailEnhancementCountry}`,
        });
        return `/incentives-v2/api/bronze/requirements?${queryParams}`;
      },
    }),
    getBronzeEligibility: build.query<BronzeEligibilityResponse, BronzeEligibilityParam>({
      query: (params) => {
        const { affAbo, period, awardCode, locale } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
        });
        return `/incentives-v2/api/bronze/eligibility?${queryParams}`;
      },
    }),
    getBronzeEarning: build.query<BronzeEarningResponse, BronzeEarningParam>({
      query: (params) => {
        const {
          affAbo,
          period,
          awardCode,
          locale,
          isoCountryCode,
          isoCurrencyCode,
          isFixedPayment,
          fixedPaymentAwardCode,
        } = params;

        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
          isoCountryCode,
          isoCurrencyCode,
        });

        // optional fields
        if (isFixedPayment) {
          queryParams.append("isFixedPayment", `${isFixedPayment}`);
        }

        if (fixedPaymentAwardCode) {
          queryParams.append("fixedPaymentAwardCode", `${fixedPaymentAwardCode}`);
        }
        return `/incentives-v2/api/bronze/earning?${queryParams}`;
      },
    }),
    getBronzeProgramStatus: build.query<ProgramStatusResponse, ProgramStatusParam>({
      query: ({ affAbo, period, primaryAwardCode, fixedPayment, restart1, restart2 }) => {
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          primaryAwardCode: `${primaryAwardCode}`,
          fixedPayment: `${fixedPayment}`,
        });

        // optional fields
        if (restart1 !== void 0) {
          queryParams.append("restart1", `${restart1}`);
        }

        if (restart2 !== void 0) {
          queryParams.append("restart2", `${restart2}`);
        }
        return `/incentives-v2/api/bronze/program/status?${queryParams.toString()}`;
      },
    }),
    getPathToBronzeOverview: build.query<PathToBronzeOverviewResponse, PathToBronzeOverviewParam>({
      query: (params) => {
        const { affAbo, period, locale, countryCode } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          locale,
          countryCode,
        });
        return `/incentives-v2/api/ptb/overview?${queryParams}`;
      },
    }),
  }),
});

export const {
  useGetBronzeRequirementsQuery,
  useGetBronzeEligibilityQuery,
  useGetBronzeEarningQuery,
  useGetBronzeProgramStatusQuery,
  useGetPathToBronzeOverviewQuery,
} = bronzeAPI;
