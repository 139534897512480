import { Trans, useTranslation } from "react-i18next";
import { useGetBronzeRequirementsQuery } from "../../../../services/bronzeIncentivesAPI";
import { useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import bronzeIncentiveProgressOrder from "../../BronzeCommon/BronzeIncentiveProgressOrder/BronzeIncentiveProgressOrder";
import { IconCheckCircle } from "../../../../common/icons/IconCheckCircle/IconCheckCircle";
import defaultContent from "../../../../content/leafIncentiveProgress";
import { TrackProgressTile } from "../../../../common/components/TrackProgressTile/TrackProgressTile";
import "./BFIIncentiveProgress.scss";

type Props = {
  awardCode: number;
  show?: boolean;
};

const BFIIncentiveProgress = ({ awardCode, show = true }: Props) => {
  const { t } = useTranslation(["leafIncentiveProgress"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });
  const { user, configuration } = boot;
  const { isoCountryCode = "" } = boot?.user || {};
  const { isoCurrencyCode = "" } = boot?.shellConfig || {};
  const { isStandardRetailEnhancementCountry = false } = configuration?.corePlus || {};

  const { data: requirementAPIResponse, isFetching: isRequirementResponseFetching } = useGetBronzeRequirementsQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
    countryCode: user.isoCountryCode,
    isStandarRetailEnhancementCountry: isStandardRetailEnhancementCountry,
  });

  const { requiredCount = 0, metCount = 0 } = requirementAPIResponse || {};
  const isLoading = isRequirementResponseFetching;

  // The order of the requirements here will also reflect on the order of (BFIRequirements) component
  const requirementsMet = bronzeIncentiveProgressOrder(requirementAPIResponse);
  const id = "Bronze Foundation Incentive Progress";

  const circlesSection = () => {
    return (
      <div className="Tracking__icons">
        {requirementsMet &&
          requirementsMet.map((isReqMet, index) => (
            <div
              key={index}
              tabIndex={0}
              aria-label={
                isReqMet
                  ? `Bronze Builder Requirement ${index + 1} Met`
                  : `Tracking Bronze Builder Requirement ${index + 1}`
              }
            >
              <IconCheckCircle key={index} id={index.toString()} color={isReqMet ? "green" : "gray"} size="xlg" />
            </div>
          ))}
      </div>
    );
  };

  const title = t("title", defaultContent.title).toString();
  const subtext = (
    <Trans>
      {t("bronzeProgressText", defaultContent.bronzeProgressText, {
        metCount,
        requiredCount,
      })}
      <span className={requiredCount === metCount ? "Tracking__bold Tracking__met" : "Tracking__bold"}></span>
    </Trans>
  );

  return (
    <TrackProgressTile
      isLoading={isLoading}
      id={id}
      title={title}
      circlesSection={circlesSection()}
      subtext={subtext}
    />
  );
};
export default BFIIncentiveProgress;
