import { Trans, useTranslation } from "react-i18next";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import defaultContent from "../../../content/ssi";
import commonDefaultContent from "../../../content/common";
import { SSIEligibility, SSIEligibilityMonth } from "../../../services/StrongStart/ssiAPI.types";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../common/leaf/LeafTileDivider/LeafTileDivider";
import { useAppSelector } from "../../../store";
import { getLongPeriodFormat, getShortMonth, getYear } from "../../../common/util/period";
import { storedLocale } from "../../impersonation/util";
import "../StrongStart.scss";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../common/icons/IconShield/IconShield";

type Props = {
  isLoading: boolean;
  eligibilityData: SSIEligibility;
  show: boolean;
  onCloseClick: Function;
};

const SSIEligibilityModal = ({ isLoading, eligibilityData, show, onCloseClick }: Props) => {
  const { t } = useTranslation(["common", "ssi"]);
  const locale = storedLocale();
  const ssiConfig = useAppSelector((state) => state.boot.configuration.strongStartIncentive);

  const title = t("eligibilityModal.title", defaultContent.eligibilityModal.title, { ns: "ssi" }).toString();

  const hasStarted = eligibilityData?.StartPeriod;

  const getBadgeTitle = (badgeType: string) => {
    return t(`${badgeType}`, commonDefaultContent[badgeType]).toString();
  };

  const renderGhostElements = (months: number) => {
    const numGhostElements = (4 - (months % 4)) % 4;
    const ghostElements = [];
    for (let i = 0; i < numGhostElements; i++) {
      ghostElements.push(<div key={i} className="SSI__eligibility-modal__grid-container__month"></div>);
    }
    return <>{ghostElements}</>;
  };

  const renderBadgeGrid = (periodList: SSIEligibilityMonth[]) => {
    return (
      <>
        {periodList.map((month) => {
          return (
            <div key={month.Period} className="SSI__eligibility-modal__grid-container__month">
              <div>
                <IconShield
                  type={month.BadgeType as BADGE_TYPE}
                  title={getBadgeTitle(month.BadgeType)}
                  size={BADGE_SIZE.MEDIUM_LARGE}
                ></IconShield>
              </div>
              <div>{getShortMonth(month.Period.toString(), locale)}</div>
            </div>
          );
        })}
        {renderGhostElements(periodList.length)}
      </>
    );
  };

  return (
    <LeafPanel {...{ show, onCloseClick, id: "ssi-eligibility-modal", title, lockBackgroundScrolling: true }}>
      <div className="SSI__eligibility-modal">
        <section id="ssi-eligibility-details">
          <LeafTile isLoading={isLoading}>
            <p className="SSI__eligibility-modal__description">
              {hasStarted ? (
                <Trans
                  i18nKey={"eligibilityModal.eligibilityDescriptionEveryMonth"}
                  ns="ssi"
                  defaults={defaultContent.eligibilityModal.eligibilityDescriptionEveryMonth}
                  components={{
                    1: <strong></strong>,
                  }}
                ></Trans>
              ) : (
                <Trans
                  i18nKey={"eligibilityModal.eligibilityDescriptionSecondFullMonth"}
                  ns="ssi"
                  defaults={defaultContent.eligibilityModal.eligibilityDescriptionSecondFullMonth}
                  components={{
                    1: <strong></strong>,
                  }}
                ></Trans>
              )}
            </p>
            <div className="SSI__eligibility-modal__date">
              <strong>
                <Trans>
                  {t("eligibilityModal.entryDate", defaultContent.eligibilityModal.entryDate, { ns: "ssi" })}
                </Trans>
                :&nbsp;
              </strong>
              {eligibilityData.EntryPeriod ? getLongPeriodFormat(eligibilityData.EntryPeriod, locale) : ""}
            </div>
            {eligibilityData.StartPeriod ? (
              <div className="SSI__eligibility-modal__date">
                <strong>
                  <Trans>
                    {t("eligibilityModal.startDate", defaultContent.eligibilityModal.startDate, { ns: "ssi" })}
                  </Trans>
                  :&nbsp;
                </strong>
                {getLongPeriodFormat(eligibilityData.StartPeriod, locale)}
              </div>
            ) : (
              <></>
            )}
            {eligibilityData.EndPeriod ? (
              <div className="SSI__eligibility-modal__date">
                <strong>
                  <Trans>{t("eligibilityModal.endDate", defaultContent.eligibilityModal.endDate, { ns: "ssi" })}</Trans>
                  :&nbsp;
                </strong>
                {getLongPeriodFormat(eligibilityData.EndPeriod, locale)}
              </div>
            ) : (
              <></>
            )}
            {eligibilityData.CurrentYear.length > 0 ? (
              <div>
                <h2>{getYear(eligibilityData.CurrentYear[0].Period.toString(), locale)}</h2>
                <div className="SSI__eligibility-modal__grid-container">
                  {renderBadgeGrid(eligibilityData.CurrentYear)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {eligibilityData.NextYear.length > 0 ? (
              <div>
                <h2>{getYear(eligibilityData.NextYear[0].Period.toString(), locale)}</h2>
                <div className="SSI__eligibility-modal__grid-container">
                  {renderBadgeGrid(eligibilityData.NextYear)}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="SSI__eligibility-modal__divider">
              <LeafTileDivider></LeafTileDivider>
            </div>
            <div className="SSI__eligibility-modal__badge-legend">
              <div className="SSI__eligibility-modal__badge-legend__item">
                <IconShield type={BADGE_TYPE.EARNED} title={getBadgeTitle(BADGE_TYPE.EARNED)}></IconShield>
                <div className="SSI__eligibility-modal__badge-legend__item__title">
                  {getBadgeTitle(BADGE_TYPE.EARNED)}
                </div>
              </div>
              <div className="SSI__eligibility-modal__badge-legend__item">
                <IconShield type={BADGE_TYPE.NOT_EARNED} title={getBadgeTitle(BADGE_TYPE.NOT_EARNED)}></IconShield>
                <div className="SSI__eligibility-modal__badge-legend__item__title">
                  {getBadgeTitle(BADGE_TYPE.NOT_EARNED)}
                </div>
              </div>
              <div className="SSI__eligibility-modal__badge-legend__item">
                <IconShield type={BADGE_TYPE.POTENTIAL} title={getBadgeTitle(BADGE_TYPE.POTENTIAL)}></IconShield>
                <div className="SSI__eligibility-modal__badge-legend__item__title">
                  {getBadgeTitle(BADGE_TYPE.POTENTIAL)}
                </div>
              </div>
              <div className="SSI__eligibility-modal__badge-legend__item">
                {hasStarted ? (
                  <>
                    <IconShield
                      type={BADGE_TYPE.UNAVAILABLE}
                      title={getBadgeTitle(BADGE_TYPE.UNAVAILABLE)}
                    ></IconShield>
                    <div className="SSI__eligibility-modal__badge-legend__item__title">
                      {getBadgeTitle(BADGE_TYPE.UNAVAILABLE)}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="SSI__eligibility-modal__badge-legend__item">
                {hasStarted ? (
                  <>
                    <IconShield
                      type={BADGE_TYPE.PAYDAY_POTENTIAL}
                      title={getBadgeTitle(BADGE_TYPE.PAYDAY_POTENTIAL)}
                    ></IconShield>
                    <div className="SSI__eligibility-modal__badge-legend__item__title">
                      {getBadgeTitle(BADGE_TYPE.PAYDAY_POTENTIAL)}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="SSI__eligibility-modal__badge-legend__item">
                {hasStarted ? (
                  <>
                    <IconShield
                      type={BADGE_TYPE.PAYDAY_ACHIEVED}
                      title={getBadgeTitle(BADGE_TYPE.PAYDAY_ACHIEVED)}
                    ></IconShield>
                    <div className="SSI__eligibility-modal__badge-legend__item__title">
                      {getBadgeTitle(BADGE_TYPE.PAYDAY_ACHIEVED)}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </LeafTile>
        </section>
        <section className="SSI__eligibility-modal__program-description">
          <h2>
            <Trans>{t("eligibilityModal.subtitle", defaultContent.eligibilityModal.subtitle, { ns: "ssi" })}</Trans>
          </h2>
          <LeafTile>
            <p>
              <Trans>
                {t("eligibilityModal.programDescription", defaultContent.eligibilityModal.programDescription, {
                  ns: "ssi",
                })}
              </Trans>
            </p>
            <p className="SSI__eligibility-modal__program-description--last">
              <Trans
                i18nKey={"eligibilityModal.moreInformation"}
                ns="ssi"
                defaults={defaultContent.eligibilityModal.moreInformation}
                components={{
                  1: <a href={ssiConfig.eligibilityDetailsLink}></a>,
                }}
              ></Trans>
            </p>
          </LeafTile>
        </section>
      </div>
    </LeafPanel>
  );
};

export default SSIEligibilityModal;
