import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { getBronzeStatus } from "../../BronzeCommon/BronzeStatus/BronzeStatus";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import defaultContent from "../../../../content/bfiBannerTile";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import { useGetBronzeEarningQuery, useGetBronzeEligibilityQuery } from "../../../../services/bronzeIncentivesAPI";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { learnMoreOnClick } from "../../../../common/tealium/BronzeIncentive/BronzeFoundation/LearnMoreButtonTrack";
import "./BFIBannerTile.scss";

type Props = {
  awardCode: number;
  isFixedPayment?: boolean;
  isCompactView?: boolean;
  fixedPaymentAwardCode: number;
  show?: boolean;
};

const BFIBannerTile = ({
  awardCode,
  isFixedPayment = false,
  isCompactView = false,
  fixedPaymentAwardCode,
  show = true,
}: Props) => {
  const { t } = useTranslation(["bfiBannerTile"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const learnMoreLink = useAppSelector(
    (store) => store.boot?.configuration?.dashboardLinks?.learnMore?.bronzeFoundation,
  );

  const { isoCountryCode = "" } = boot?.user || {};
  const { isoCurrencyCode = "" } = boot?.shellConfig || {};
  const affAbo = getLoginAffAbo({ boot });

  const { data: earningAPIResponse, isFetching: isEarningDataFetching } = useGetBronzeEarningQuery({
    affAbo,
    period: selectedPeriod,
    awardCode,
    locale,
    isoCountryCode,
    isFixedPayment,
    fixedPaymentAwardCode,
    isoCurrencyCode,
  });

  const { data: eligibilityAPIResponse, isFetching: isEligibilityDataFetching } = useGetBronzeEligibilityQuery({
    affAbo,
    period: selectedPeriod,
    awardCode,
    locale,
  });

  const {
    status = "",
    method = "",
    consistencyPayment = {
      target: 0,
      actual: 0,
      met: false,
      schedule: [],
      basePayment: {
        amount: 0,
        amountCurrency: "",
      },
      additionalPayment: {
        amount: 0,
        amountCurrency: "",
      },
      totalPayment: {
        amount: 0,
        amountCurrency: "",
      },
    },
  } = earningAPIResponse || {};
  const { eligible = false, graduated = false } = eligibilityAPIResponse || {};

  const isLoading = isEarningDataFetching === true || isEligibilityDataFetching === true;

  if (!isLoading) {
    const overridedStatus = getBronzeStatus({
      status,
      method,
      eligible,
      graduated,
    });

    const bannerMessages = [
      {
        status: [
          QUALIFICATION_STATUS.NOT_QUALIFIED,
          QUALIFICATION_STATUS.ELIGIBLE,
          QUALIFICATION_STATUS.PENDING_ELIGIBLE,
          QUALIFICATION_STATUS.REWORK_DISQUALIFIED,
        ],
        title: `${t("keepGoing", defaultContent["keepGoing"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.DENIED, QUALIFICATION_STATUS.NOT_ELIGIBLE],
        title: `${t("notEligible", defaultContent["notEligible"], { dateText: getBronzeDisplayDate(selectedPeriod) })}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.QUALIFIED, QUALIFICATION_STATUS.TRACKING],
        title: `${t("greatJob", defaultContent["greatJob"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
        title: `${t("manual", defaultContent["manual"])}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.HELD],
        title: `${t("onHold", defaultContent["onHold"])}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
    ];

    const statusToHideIncentive = [
      QUALIFICATION_STATUS.DENIED,
      QUALIFICATION_STATUS.NOT_ELIGIBLE,
      QUALIFICATION_STATUS.HELD,
    ];

    if (show === false) return <></>;

    return (
      <div className="BFIBannerTile">
        <LeafIncentiveBannerTile
          bannerMessages={bannerMessages}
          incentive={{
            title: `${t("incentiveLabel", defaultContent["incentiveLabel"])}`,
            status: overridedStatus,
            amount: consistencyPayment.totalPayment.amountCurrency, // The API will sum the base bonus and consistency payment to calculate the total. If the consistency payment is unavailable, the total will still reflect the base bonus amount.
            statusToHideIncentive,
          }}
          learnMoreLink={learnMoreLink}
          isLoading={isLoading}
          isCompactView={isCompactView}
          learnMoreTealiumFunction={learnMoreOnClick}
        />
      </div>
    );
  }
  return <LeafTile isLoading={isLoading} />;
};

export default BFIBannerTile;
